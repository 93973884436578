import { useEffect, useState } from "react";
import { NotNullUndef } from "../../utils/type_check";
import CustomButton from "./button.component";
import TimerWidget from "./timer.component copy";
import { Form } from "antd-mobile"
import Cleave from "cleave.js/react"
import "../styles/card.css"
import { recognize } from "../../helpers/cards/card.validation"

const CardComponent = (props: any) => {

    let [card, setCard] = useState()
    let [bank, setBank] = useState()
    
    
    useEffect(() => {
        if (props.card !== undefined && props.card !== null) {
            handleInputs(props.card)
        }
    },[])
 
    const handleInputs = (e: any) => {

        var _bank = recognize(e)
        if (_bank !== false) {
            // @ts-ignore
            setBank(_bank)
            setCard(e);
            props.onCardUpdate(e)
        } else {
            // @ts-ignore
            setCard(null);
            props.onCardUpdate(e)
            // @ts-ignore
            setBank(null)
        }
    }


    return (
        <div className={"card-element" + (props.validation.is_valid === true ? "" : " card-invalid")}>
            <div style={{ width: "100%", }}>
                <div className="sorter">
                    <div className="card-logo"></div>
                </div>
                <div className="row-detail">
                    <p className="title-style bold">شماره کارت</p>
                    {/* {bank ? <p className="title-low-sized bank-name">{bank.logo}</p> : <div></div>} */}
                </div>
                {/* @ts-ignore */}
                <p className="title-low-sized">شماره ۱۶ رقمی درج شده روی کارت {bank ? bank.persianName : ''} را وارد کنید</p>
                <div className="sized-box-height-10"></div>
            </div>
            <div className="input-handler" id="card-element-inputs" >
                {
                    bank ?
                        // @ts-ignore 
                        <div className="bank-icon" style={{ backgroundImage: `url('/images/${bank ? bank.logo : ''}')` }}></div>
                        : <div></div>
                }
                <Form  >
                    <Form.Item label="" >
                        <Cleave
                            dir="ltr"
                            className="adm-input center-text"
                            value={props.card}
                            disabled={props.card}
                            placeholder="0000 0000 0000 0000"
                            options={{ creditCard: true }}
                            onChange={event => { handleInputs(event.target.rawValue) }}
                        />
                    </Form.Item>
                </Form>
            </div>
            {props.children}
            <div className="sized-box-height-20"></div>
        </div>
    )
}
export default CardComponent;