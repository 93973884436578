


var initial = {
    data: null,
    is_loading: false
}

export const LoginTypes = {
    LOGIN_USER: "LOGIN_USER"
}


export const loginReducer = (state = initial, action: any) => {
    switch (action.type) {
        case LoginTypes.LOGIN_USER:
            return {
                ...state,
                type: action.type,
                data: action.data,
                is_loading: action.is_loading
            }
        default:
            return {...state}
    }
}