import axios, { AxiosError } from "axios";
import axiosRetry, { exponentialDelay , isRetryableError } from "axios-retry"










// Init instance of axios which works with BASE_URL



export const getRequester = () => {
    var BASE_URL = window.location.host.replace(/^[^.]+\./g, "");
    const axiosInstance = axios.create({ baseURL: "https://" + "api." + BASE_URL });
    axiosRetry(axiosInstance, {
        retryDelay: exponentialDelay,
        retryCondition: (err: AxiosError) :boolean => {
            if (!err.response) return false;
            // @ts-ignore
            if (err.response?.status == 404 && err.response.data.message == "خطای سیستمی, لطفا مجددا تلاش نمایید!") {
                return true;
            }
            return false;
        }
    })
    return axiosInstance;
}
