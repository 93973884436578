


export function ParseURI() {

    var _path = window.location.pathname.split("/payments/")[1];
    var _transaction = _path.split("/")[1]
    var _merchent = _path.split("/")[0];
    return {
        merchent: _merchent,
        transaction: _transaction
    }
}




export function parseAuths(auth: any, options: string = "") {

    var _parsedAuth = "";
    Object.keys(auth).forEach(key => {
        _parsedAuth += (key.toUpperCase().replace("_", "-")) + "=" + auth[key] + "; ";
    })
    if (options === "add") {
        var _ids = sessionStorage.getItem("IDS");
        var _updated = _ids + " " + _parsedAuth;
        sessionStorage.setItem("IDS", _updated)
    }else {
        sessionStorage.setItem("IDS", _parsedAuth);
    }
}