
import * as React from 'react';
import { connect } from "react-redux"
import { getInitialDataAction } from "../../store/features/initial/initial.action"
import PaymentComponent from "../../components/elements/payment.component"
import { useEffect, useState } from 'react';
import './styles/payment.css'
import { paymentInquiryAction } from '../../store/features/payment_first/payment.action';
import LoadingPage from '../../components/elements/loading';



// @ts-ignore
const Home = (props) => {

  var [vendor, setVendor] = useState(null)

  React.useEffect(() => {
    props.getInitialDataAction(setPageDetails);
  }, [vendor]);

  const setPageDetails = (data: any) => {
    if (data != null) {
      let dir = "rtl";
      let lang = "fa";

      // @ts-ignore
      document.querySelector("html").setAttribute("dir", dir);
      // @ts-ignore
      document.title = "Parscoinpay | " + data.name;
      // @ts-ignore
      document.querySelector("html").setAttribute("lang", lang);
      // setVendor(data)
    }

  }

  return (
    <div className="home-page" >
      {/* <LoadingPage></LoadingPage> */}
      {/*
         @ts-ignore */}
      {props.home.is_loading ? <LoadingPage></LoadingPage> : <PaymentComponent query={props.home.data} />}
    </div>
  );
};



const mapPropsToState = (props: any) => {
  return {
    home: props.initial
  }
}

export default connect(mapPropsToState, { getInitialDataAction })(Home);
