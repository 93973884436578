import { combineReducers } from "redux";
import { finalizeReducer } from "./finalize/finalize.reducer";
import { initalDataReducer } from "./initial/initial.reducer";
import { loginReducer } from "./login/login.reducer";
import { OTPReducer } from "./otp/otp.reducer";
import { paymentInquiryReducer } from "./payment_first/payment.reducer";
import { pinReducer } from "./pin/pin.reducer";




export default combineReducers({
    initial: initalDataReducer,
    login: loginReducer,
    otp: OTPReducer,
    pin: pinReducer,
    inquiry: paymentInquiryReducer,
    payment: finalizeReducer
})