import React, { useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux"
import store from "./store/store"
import {
  GoogleReCaptchaProvider, useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';

ReactDOM.render(
  // <GoogleReCaptchaProvider reCaptchaKey="6Ld50X8cAAAAAIAik73gOUKvxOQSHEVmldCuj72D" >
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  // </GoogleReCaptchaProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
