import "../styles/phone.css"
import CustomButton from "./button.component";




// @ts-ignore
const PhoneInputComponent = (props: any) => {
    return (
        <div className="phone-input-element card-element">
            <div>
                <h2 className="title-style">
                    شماره تلفن همراه
                </h2>
                <p className="title-low-sized">
                    لطفا برای ادامه پرداخت شماره تلفن همراه متصل به حساب بانکی استفاده شده را وارد نمایید
                </p>
            </div>
            <div className="sized-box-height-20"></div>
            <div className="input-continue">
                <input required type="text" disabled={props.phone} value={props.phone} pattern="^(0)9(\d[1-9]|[1 3]\d|2[0-2]|98)\d{7}$" onChange={(e: any) => props.onPhoneSet(e.target.value)} className="input-style phone-input" placeholder="09XXXXXXXXX" ></input>
                <div className="sized-box-width-20"></div>
                <CustomButton text={"ادامه پرداخت"} disabled={props.disabled} onClick={(e: any) => props.onLogin(e)}></CustomButton>
            </div>
        </div >
    )
}
export default PhoneInputComponent;