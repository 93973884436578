import { ParseURI } from "../../../utils/parse_uri";
import { getRequester } from "../../configs";
import { InitialTypes } from "./initial.reducer"





export const getInitialDataAction = (cb: Function) => (dispatch: any) => {
    dispatch({
        type: InitialTypes.GET_TRANSACTION,
        data: null,
        is_loading: true
    });

    getRequester().get("/payments" + "/" + ParseURI().merchent + "/" + ParseURI().transaction)
        .then(body => {
            dispatch({
                type: InitialTypes.GET_TRANSACTION,
                data: body.data,
                is_loading: false
            })
            return cb(body.data)
        })
        .catch(err => {
            
            return dispatch({
                type: InitialTypes.GET_TRANSACTION,
                data: null,
                is_loading: false
            });
        })
}


