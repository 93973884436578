import "../styles/navigation_bar.css"




const NavigationBarComponent = (props: any) => {
    let _currentSelection = props.selected || 0;
    return  (
        <div className="navigation-bar-element">
            <div onClick={() => props.onSelect(0)} className={"nav-item" + (_currentSelection == 0 ? " nav-item-selected" : "")}>
                <div className="nav-item-icon add"></div>
                <h2 className="title-low-sized white-text">
                    ثبت نام
                </h2>
            </div>
            <div onClick={() => props.onSelect(1)} className={"nav-item" + (_currentSelection == 1 ? " nav-item-selected" : "")}>
                <div className="nav-item-icon phone"></div>
                <h2 className="title-low-sized white-text">
                    تایید کد
                </h2>
            </div>
            <div onClick={() => props.onSelect(2)} className={"nav-item" + (_currentSelection == 2 ? " nav-item-selected" : "")}>
                <div className="nav-item-icon info"></div>
                <h2 className="title-low-sized white-text">
                    اطلاعات
                    پرداخت
                </h2>
            </div>
            <div onClick={() => props.onSelect(3)} className={"nav-item" + (_currentSelection == 3 ? " nav-item-selected" : "")}>
                <div className="nav-item-icon help"></div>
                <h2 className="title-low-sized white-text">
                    وضعیت
                    پرداخت
                </h2>
            </div>
        </div>
    )
}
export default NavigationBarComponent;