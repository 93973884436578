import { act } from "react-dom/test-utils";




var initState = {
    is_loading: false,
    data: null
}

export const FinalizeTypes = {
    FINALIZE_PAYMENT: "FINALIZE_PAYMENT"
}


export const finalizeReducer = (state = initState, action: any ) =>  {
    switch (action.type) {
        case FinalizeTypes.FINALIZE_PAYMENT:
            return {
                ...state,
                type: action.type,
                data: action.data,
                is_loading: action.is_loading
            }
        default:
            return { ...state }
    }
}