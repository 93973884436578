import React, { Component } from 'react';
import { MoonLoader } from "react-spinners"
import '../styles/loading.css'
import { useState } from "react";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/HashLoader";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function LoadingPage() {
    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#FC982F");

    return (
        <div className="loading-page">
            <ClipLoader color={color} loading={loading} css={override} size={60} />
            <p>درحال بررسی اطلاعات پرداخت...</p>
        </div>
    );
}


export default LoadingPage;