import { toast } from "react-toastify"
import { handleApiCallErrors } from "../../../utils/error_handler"
import { parseAuths } from "../../../utils/parse_uri"
import { getRequester } from "../../configs"
import { OTPTypes } from "./otp.reducer"





export const VerifyOTPCode = (code: string, cb: Function) => (dispatch: any) => {


    dispatch({
        type: OTPTypes.VERIFY_OTP_REQUEST,
        data: null,
        is_loading: true
    })

    var _toastPromise = getRequester().post("/v1/payments/verifyOTP", {
        verification_code: code
    }, {
        headers: {
            // @ts-ignore
            IDS: sessionStorage.getItem("IDS")
        }
    })
        .then(async body => {
            cb();
            // @ts-ignore
            parseAuths(body.data.data.payment, 'add');
            return dispatch({
                type: OTPTypes.VERIFY_OTP_REQUEST,
                data: body.data,
                is_loading: false
            })
        })
    toast.promise(_toastPromise, {
        pending: "درحال تایید کد ورود...",
        success: "کد تایید شد. لطفا اطلاعات کارت را وارد کنید...",
        error: ""
    }).catch(err => {
        handleApiCallErrors(err);
        return dispatch({
            type: OTPTypes.VERIFY_OTP_REQUEST,
            data: null,
            is_loading: false
        })
    })


}