


var initialState = {
    data: null,
    is_loading: false
}

export const OTPTypes = {
    VERIFY_OTP_REQUEST: "VERIFY_OTP_REQUEST" 
}


export const OTPReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case OTPTypes.VERIFY_OTP_REQUEST:
            return {
                ...state,
                type: action.type,
                data: action.data,
                is_loading: action.is_loading
            }
        default:
            return { ...state };
    }
} 