import './styles/blocked.css'



// @ts-ignore
const BlockedPage = () => {
    return (
        <div className="payment-expired">
            <div className="payment-exp-wrapper">
                <h1 className="title-style">خطا هنگام پرداخت</h1>
                <h1 className="title-low-sized center-align">متاسفانه پذیرنده مورد نظر درحال حاضر قادر به دریافت تراکنش نمیباشد. لطفا بعدا تلاش نمایید.</h1>
            </div>
        </div>
    )
}
export default BlockedPage;