import { useEffect, useState } from "react";
import CardComponent from "./card.component";
import VendorInfo from "./vendor.component";
import "../styles/payment_details.css"
import { connect } from "react-redux"
import { paymentInquiryAction } from "../../store/features/payment_first/payment.action";
import { NotNullUndef } from "../../utils/type_check";
import { sendDynamicPin } from "../../store/features/pin/pin.action";
import { finalizePayment } from "../../store/features/finalize/finalize.action";
import CustomButton from "./button.component";
import ExpAndSecurity from "./exp_security";
const shetabIran = require("iran-shetab")




// @ts-ignore
const PaymentDetailsComponent = ({ vendor, ...props }) => {

  let [year, setYear] = useState("")
  let [month, setMonth] = useState("")
  let [cvv2, setCvv] = useState("")
  let [card, setCard] = useState(null);
  let [pin2, setPin2] = useState(null);
  let [validation, setValidation] = useState({
    is_valid: false
  })

  let [cardSet, SetCard] = useState(false)


  useEffect(() => {
    if (NotNullUndef(props.card)) {
      let _cardNumber = (props.card as string).match(/.{1,4}/g) as any;
      document.getElementById("first")?.setAttribute('value', _cardNumber[0]);
      document.getElementById("second")?.setAttribute('value', _cardNumber[1]);
      document.getElementById("third")?.setAttribute('value', _cardNumber[2]);
      document.getElementById("fourth")?.setAttribute('value', _cardNumber[3]);

      document.getElementById('first')?.setAttribute('disabled', 'true');
      document.getElementById('second')?.setAttribute('disabled', 'true');
      document.getElementById('third')?.setAttribute('disabled', 'true');
      document.getElementById('fourth')?.setAttribute('disabled', 'true');

      setCard(props.card)
      SetCard(true)
    }
  }, [cardSet])


  const cardUpdated = (first: any, second: any, third: any, fourth: any, expMonth: any, expYar: any, cvv2: string, pin: string) => {
    let _card = first + second + third + fourth;
    let _cardInfo = shetabIran.recognize(_card);
    let _isValid = shetabIran.isValid(_card);
    if (NotNullUndef(expMonth)) setMonth(expMonth)
    if (NotNullUndef(expYar)) setYear(expYar)
    // @ts-ignore
    if (NotNullUndef(pin)) setPin2(pin)
    if (_isValid == true) {
      setCard(_card);
      setValidation({
        is_valid: true
      })
    } else {
      setCard(null);
      setValidation({
        is_valid: false
      })
    }
  }


  var sendPin = (e: any) => {
    props.sendDynamicPin(props.inquiry.data.transaction.trace)
  }

  var proceedPayment = (e: any) => {
    var _form = document.getElementById("inquiry-card");
    // @ts-ignore
    if (_form.checkValidity()) {
      e.preventDefault();
      // @ts-ignore
      props.finalizePayment(props.inquiry.data.transaction.trace, props.card, month, year, cvv2, pin2, goToFinalize)
    }
  }



  var goToFinalize = () => {
    props.goNext()
  }


  var proceedInquiry = (event: any,exp: any, cvv: any) => {
    var _form = document.getElementById("inquiry-card");
    // @ts-ignore
    if (_form.checkValidity()) {
      event.preventDefault();
      let _expMonth = exp[0]
      let _expYear = exp[1].replace("14","")
      setMonth(_expMonth);
      setYear(_expYear)
      setCvv(cvv);
      // @ts-ignore
      props.paymentInquiryAction(props.card, _expMonth, _expYear, cvv)
    }
  }

  return (
    <div className="payment-details-element">
      <VendorInfo query={vendor} />
      <div className="sized-box-height-10"></div>
      <h1 className="title-style">اطلاعات پرداخت</h1>
      <div className="sized-box-height-10"></div>
      <form id="inquiry-card">
        <CardComponent card={props.card}  disabled={props.inquiry.is_loading}  paymentState={NotNullUndef(props.inquiry.data) ? props.inquiry.data.next : ""} onCardUpdate={cardUpdated} validation={validation} withExtra={true}>
          <ExpAndSecurity setPin={setPin2} sendOtp={sendPin} otpSent={NotNullUndef(props.inquiry.data) ? props.inquiry.data.otp_sent : false} onInquiry={(event: any,e: any, cvv: any) => proceedInquiry(event,e, cvv)} paymentState={NotNullUndef(props.inquiry.data) ? props.inquiry.data.next : ""}></ExpAndSecurity>
        </CardComponent>
        <div className="sized-box-height-10"></div>
        {NotNullUndef(props.inquiry.data) && props.inquiry.data.next == "verify"
          ? <div className="card-element" style={{ width: "100%", alignItems: "center" }}>
            <p className="title-low-sized">لطفا از اشتراک گذاری رمز دوم اینترنتی خود با دیگران خوداری نمایید!</p>
            <div className="sized-box-height-10"></div>
            <CustomButton disabled={props.payment.is_loading || false} text={"انجام پرداخت"} onClick={(e: any) => proceedPayment(e)} style={{ width: "100%" }}></CustomButton>
          </div>
          : <div></div>
        }
        <div className="sized-box-height-40"></div>
      </form>
    </div>
  )
}

const mapPropsToState = (props: any) => {
  return {
    inquiry: props.inquiry,
    payment: props.payment,
    pin: props.pin
  }
}

export default connect(mapPropsToState, { paymentInquiryAction, sendDynamicPin, finalizePayment })(PaymentDetailsComponent);