

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../styles/404.css'
class NotFoundPage extends Component {
    render() {
        return (
            <div id="n-main">
                <div className="fof">
                    <h1 style={{ margin: "0" }}>پیدا نشد </h1>
                    <p style={{ margin: "0" }}>صحفه مورد نظر پیدا نشد، لطفا آدرس را بررسی نموده و دوباره تلاش کنید.</p>
                </div>
            </div>
        );
    }
}


export default NotFoundPage;