import { AxiosError } from "axios";
import { toast } from "react-toastify";

export function handleApiCallErrors(error: any) {
    var defaultMessage = "خطا در ارتباط با سرور. لطفا مجددا تلاش کنید";
    if (error == null || error.response == null) {
        return toast.error(defaultMessage);
    }
    if (error.response?.data != null) {
        // @ts-ignore
        return toast.error(error.response?.data?.message);
    }
    return toast.error(defaultMessage);
}