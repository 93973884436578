import { Form, Picker } from "antd-mobile";
import { useState } from "react";
import CustomButton from "./button.component";
import TimerWidget from "./timer.component copy";
import MediaQuery from 'react-responsive';

const exp = [
    ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
    ["1400", "1401", "1402", "1403", "1404", "1405", "1406", "1407", "1408", "1409", "1410"]
]



function ExpAndSecurity(props: any) {




    let [codeSend, setCodeSend] = useState(false);
    let [visible, setVisible] = useState(false)
    let [year, setYear] = useState()
    let [month, setMonth] = useState()


    let [value, setValue] = useState<(string | null)[]>(["01", "1400"])
    let [cvv, setCvv] = useState()


    const timeout = () => {
        setCodeSend(false);
    }


    const performInquiry = (e: any) => {
        if (year !== undefined && month !== undefined) {
            props.onInquiry(e, [month, "14" + year] , cvv)
        }else {
            props.onInquiry(e, value, cvv)
        }
    }



    return (
        <div className="exp-cvc">
            <div className="sized-box-height-10"></div>
            <h1 className="title-style">شماره شناسایی دوم (CVV2):</h1>
            <p className="title-low-sized">شماره CVV 2 درج شده روی کارت را وارد نمایید</p>
            <div className="sized-box-height-10"></div>
            <div className="cvc-handler">
                {/* @ts-ignore */}
                <input minLength={3} onChange={(e) => setCvv(e.target.value)} pattern="[0-9]{3,4}" required className="input-style card-item" type="text" id="cvv2" placeholder="CVV2" maxLength={4}></input>
            </div>
            <div className="sized-box-height-10"></div>
            <h1 className="title-style">تاریخ انقضا کارت:</h1>
            <p className="title-low-sized">ماه و سال تاریخ انقضای کارت را وارد کنید</p>
            <div className="sized-box-height-10"></div>
            <MediaQuery maxDeviceWidth={1224}>
                <div className="exp-handler" onClick={() => setVisible(true)}>
                    {/* @ts-ignore */}
                    <input pattern="[0-9]{,2}" disabled minLength={2} value={value[1]} required className="input-style card-item" id="year" type="text" placeholder={"ماه"} maxLength={2}></input>
                    <div className="sized-box-width-10"></div>
                    {/* @ts-ignore */}
                    <input pattern="[0-9]{,2}" disabled minLength={2} value={value[0]} required className="input-style card-item" id="month" type="text" placeholder={"سال"} maxLength={2} ></input>
                </div >
            </MediaQuery>
            <MediaQuery minDeviceWidth={1224}>
                <div className="exp-handler" >
                    {/* @ts-ignore */}
                    <input pattern="[0-9]{,2}" minLength={2} onChange={(e) => setYear(e.target.value)} required className="input-style card-item" id="year" type="text" placeholder={"سال"} maxLength={2}></input>
                    <div className="sized-box-width-10"></div>
                    {/* @ts-ignore */}
                    <input pattern="[0-9]{,2}" minLength={2} onChange={(e) => setMonth(e.target.value)} required className="input-style card-item" id="month" type="text" placeholder={"ماه"} maxLength={2} ></input>
                </div >
            </MediaQuery>
            {props.paymentState === "verify" ?
                <div>
                    <div className="sized-box-height-10"></div>
                    <h1 className="title-style">رمز دوم اینترنتی کارت:</h1>
                    <p className="title-low-sized">رمز دوم خود را وارد کنید</p>
                    <div className="sized-box-height-10"></div>
                    <div className="exp-handler">
                        {props.otpSent == true ? <div></div> : codeSend ? <TimerWidget onTimeout={timeout}></TimerWidget> : <CustomButton onTimeout={timeout} isTimer={true} disabled={codeSend} onClick={(e: any) => {
                            props.sendOtp(e);
                            setCodeSend(true)
                        }} text={"دریافت رمز دوم"} className="button-element"></CustomButton>}
                        {props.otpSent == true ? <div></div> : <div className="sized-box-width-10"></div>}
                        {/* @ts-ignore */}
                        <input pattern="[0-9]{4,8}" onChange={(e) => props.setPin(e.target.value)} required minLength={4} className="input-style card-item" id="pin" type="password" placeholder="رمز دوم" maxLength={10}></input>
                    </div>
                </div>
                :
                <div>
                    <div className="inquiry-handler" >
                        <div className="sized-box-height-10"></div>
                        <p className="title-low-sized">برای نهایی شدن پرداخت. باید اطلاعات پرداخت تایید شود.</p>
                        <div className="sized-box-height-10"></div>
                        <CustomButton text={"تایید اطلاعات پرداخت"} disabled={props.disabled} onClick={(e: any) => performInquiry(e)} ></CustomButton>
                    </div>
                </div>
            }
            <Picker
                columns={exp}
                visible={visible}
                cancelText={"لغو"}
                confirmText={"تایید"}
                onClose={() => {
                    setVisible(false)
                }}
                value={value}
                onConfirm={setValue}
            />
        </div >
    )
}


export default ExpAndSecurity