import { toast } from "react-toastify";
import { handleApiCallErrors } from "../../../utils/error_handler";
import { ParseURI } from "../../../utils/parse_uri"
import { getRequester } from "../../configs";
import { PaymentInquiryTypes } from "./payment.reducer";





export const paymentInquiryAction = (card: string, month: string, year: string, cvv2: string, pin: string = "123456") => (dispatch: any) => {
    var { merchent, transaction } = ParseURI();
    dispatch({
        type: PaymentInquiryTypes.INQUIRY_PAYMENT,
        data: null,
        is_loading: true
    })

    var _toastPromise = getRequester().post("/v1/payments/paymentInitiated", {
        "merchent_id": merchent,
        "transaction_uuid": transaction,
        "source_card": card,
        "pin": pin,
        "exp_month": month,
        "exp_year": year,
        "cvv2": cvv2
    }, {
        headers: {
            // @ts-ignore
            "IDS": sessionStorage.getItem("IDS")
        }
    }).then(body => {
        return dispatch({
            type: PaymentInquiryTypes.INQUIRY_PAYMENT,
            // @ts-expect-error
            data: body.data.data,
            is_loading: false
        })
    })


    toast.promise(_toastPromise, {
        pending: "درحال بررسی اطلاعات پرداخت...",
        success: "اطلاعات پرداخت تایید شد. لطفا رمز دوم را وارد نمایید...",
        error: "",
    }).catch(err => {
        handleApiCallErrors(err);

        dispatch({
            type: PaymentInquiryTypes.INQUIRY_PAYMENT,
            data: null,
            is_loading: false
        })
    })
}






