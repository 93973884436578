
import './styles/expired.css'




const PaymentExpired = () => {
    return (
        <div className="payment-expired">
            <div className="payment-exp-wrapper">
                <h1 className="title-style">پرداخت منقضی یا قبلا انجام شده است</h1>
                <h1 className="title-low-sized center-align">متاسفانه پرداخت مورد نظر منقضی یا کامل شده است لطفا مجددا از پذیرنده درخواست پرداخت نمایید</h1>
            </div>
        </div>
    )
}
export default PaymentExpired;