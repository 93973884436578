


var initState = {
    is_loading: false,
    data: null
}



export const PinTypes = {
    SEND_PIN: "SEND_PIN"
}

export const pinReducer = (state = initState, action: any) => {
    switch (action.type) {
        case PinTypes.SEND_PIN:
            return  {
                ...state,
                type: action.type,
                data: action.data,
                is_loading: action.is_loading
            }
        default:
            return {...state}
    }
}