import { Router } from "react-router";
import { toast } from "react-toastify";
import { handleApiCallErrors } from "../../../utils/error_handler";
import { parseAuths, ParseURI } from "../../../utils/parse_uri";
import { getRequester } from "../../configs";
import { LoginTypes } from "./login.reducer";






export const loginUserWithPhone = (phone: string, gateway_type: string, email: string, cardNumber: string, cb: Function, silent = false) => (dispatch: any) => {
    dispatch({
        type: LoginTypes.LOGIN_USER,
        data: null,
        is_loading: true
    });

    var _toastPromise = getRequester().post("/v1/payments/preparingPaymentSetup", {
        merchent_id: ParseURI().merchent,
        transaction_uuid: ParseURI().transaction,
        phone_number: phone,
        email: email,
        gateway_type: gateway_type,
        card_number: cardNumber
    })
        .then((body: any) => {
            if (body.data.data.next_step === "PREPARE") {
                cb(2)
            }else {
                cb(1)
            }
            // @ts-ignore
            parseAuths(body.data.data.auth)
            if (body.data.data.next_step === "PREPARE") {
                parseAuths(body.data.data.otp,'add')
            }
            return dispatch({
                type: LoginTypes.LOGIN_USER,
                data: body.data,
                is_loading: false
            });
        })

    if (silent === true) {
        dispatch({
            type: LoginTypes.LOGIN_USER,
            data: null,
            is_loading: false
        });
    } else {
        toast.promise(_toastPromise, {
            pending: "درحال ورود به سیستم...",
            error: "",
            success: "شما با موفقیت وارد شدید لطفا اطلاعات الزامی را کامل کنید."
        }).catch(err => {
            handleApiCallErrors(err);
            dispatch({
                type: LoginTypes.LOGIN_USER,
                data: null,
                is_loading: false
            });
        })
    }
}


