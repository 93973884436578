


export var banks = [
    {
        "name": "صادرات ایران",
        "code": "603769",
        "nameEn": "SADERAT",
        "logoUrl": "bank-saderatiran-logo-min.png"
    },
    {
        "name": "کارآفرین",
        "code": "627488",
        "nameEn": "KARAFARIN",
        "logoUrl": "bank-karafarin-logo-min.png"
    },
    {
        "name": "مسکن",
        "code": "628023",
        "nameEn": "MASKAN",
        "logoUrl": "bank-maskan-logo-min.png"
    },
    {
        "name": "ملت",
        "code": "610433",
        "nameEn": "MELLAT",
        "logoUrl": "bank-mellat-logo-min.png"
    },
    {
        "name": "ملی ایران",
        "code": "603799",
        "nameEn": "MELLI",
        "logoUrl": "bank-melli-logo-min.png"
    },
    {
        "name": "پاسارگاد",
        "code": "502229",
        "nameEn": "PASARGAD",
        "logoUrl": "bank-pasargad-logo-min.png"
    },
    {
        "name": "تجارت",
        "code": "585983",
        "nameEn": "TEJARAT",
        "logoUrl": "bank-tejarat-logo-min.png"
    },
    {
        "name": "پارسیان",
        "code": "622106",
        "nameEn": "PARSIAN",
        "logoUrl": "bank-parsian-logo-min.png"
    },
    {
        "name": "اقتصاد نوین",
        "code": "627412",
        "nameEn": "EGHTESAD_NOVIN",
        "logoUrl": "bank-eghtesatenovin-logo-min.png"
    },
    {
        "name": "آینده",
        "code": "636214",
        "nameEn": "AYANDEH",
        "logoUrl": "bank-ayandeh-logo-min.png"
    },
    {
        "name": "دی",
        "code": "502938",
        "nameEn": "DAY",
        "logoUrl": "bank-day-logo-min.png"
    },
    {
        "name": "سامان",
        "code": "621986",
        "nameEn": "SAMAN",
        "logoUrl": "bank-saman-logo-min.png"
    },
    {
        "name": "انصار",
        "code": "627381",
        "nameEn": "ANSAR",
        "logoUrl": "bank-ansar-logo-min.png"
    },
    {
        "name": "شهر",
        "code": "504706",
        "nameEn": "SHAHR",
        "logoUrl": "bank-shahr-logo-min.png"
    },
    {
        "name": "پست بانک ایران",
        "code": "627760",
        "nameEn": "POST",
        "logoUrl": "bank-postbank-logo-min.png"
    },
    {
        "name": "مهر اقتصاد",
        "code": "639370",
        "nameEn": "MEHR_EGHTESAD",
        "logoUrl": "bank-malivaetebarimehr-logo-min.png"
    },
    {
        "name": "سرمایه",
        "code": "639607",
        "nameEn": "SARMAYEH",
        "logoUrl": "bank-sarmayeh-logo-min.png"
    },
    {
        "name": "قوامین",
        "code": "639599",
        "nameEn": "GHAVAMIN",
        "logoUrl": "bank-ghavamin-logo-min.png"
    },
    {
        "name": "حکمت ایرانیان",
        "code": "636949",
        "nameEn": "HEKMAT",
        "logoUrl": "bank-hekmatiranian-logo-min.png"
    },
    {
        "name": "صنعت و معدن",
        "code": "627961",
        "nameEn": "SANATMADAN",
        "logoUrl": "bank-sanatvamadan-logo-min.png"
    },
    {
        "name": "گردشگری",
        "code": "505416",
        "nameEn": "GARDESHGARI",
        "logoUrl": "bank-ghardeshgary-logo-min.png"
    },
    {
        "name": "ایران زمین",
        "code": "505785",
        "nameEn": "IRANZAMIN",
        "logoUrl": "bank-iranzamin-logo-min.png"
    },
    {
        "name": "خاورمیانه",
        "code": "585947",
        "nameEn": "KHAVARMEYANEH",
        "logoUrl": "bank-khavarmiyaneh-logo-min.png"
    },
    {
        "name": "رفاه کارگران",
        "code": "589463",
        "nameEn": "REFAH",
        "logoUrl": "bank-refahekargaran-logo-min.png"
    },
    {
        "name": "سپه",
        "code": "589210",
        "nameEn": "SEPAH",
        "logoUrl": "bank-sepah-logo-min.png"
    },
    {
        "name": "کشاورزی",
        "code": "603770",
        "nameEn": "AGRI",
        "logoUrl": "bank-keshavarzi-logo-min.png"
    },
    {
        "name": "اداره معاملات بانک مرکزی",
        "code": "636795",
        "nameEn": "TRADE_DEPARTMENT_CBI",
        "logoUrl": "bank-markazi-logo-min.png"
    },
    {
        "name": "موسسه اعتباری نور",
        "code": "507677",
        "nameEn": "NOOR",
        "logoUrl": "bank-etebarinoor-logo-min.png"
    },
    {
        "name": "قرض الحسنه مهر ایران",
        "code": "606373",
        "nameEn": "MEHR_IRAN",
        "logoUrl": "bank-gharzalhasanemehreiran-logo-min.png"
    },
    {
        "name": "مشترک ایران–ونزوئلا",
        "code": "581874",
        "nameEn": "IRAN_VENEZUELA",
        "logoUrl": "bank-iranvawenezoella-logo-min.png"
    },
    {
        "name": "موسسه اعتباری کوثر",
        "code": "505801",
        "nameEn": "KOSAR",
        "logoUrl": "bank-malivaetebarikosar-logo-min.png"
    },
    {
        "name": "موسسه اعتباری ملل",
        "code": "606256",
        "nameEn": "MELAL",
        "logoUrl": "bank-malivaetebarimellal-logo-min.png"
    },
    {
        "name": "موسسه اعتباری ثامن",
        "code": "504165",
        "nameEn": "UNKNOWN",
        "logoUrl": "bank-malivatetebarisamen-logo-min.png"
    },
    {
        "name": "موسسه اعتباری توسعه",
        "code": "628157",
        "nameEn": "TOSSEEH_CREDIT_INSTITUTE",
        "logoUrl": "bank-moasseetebaritoseeh-logo-min.png"
    },
    {
        "name": "قرض الحسنه رسالت",
        "code": "504172",
        "nameEn": "RESALAT",
        "logoUrl": "bank-resalat-logo-min.png"
    },
    {
        "name": "توسعه تعاون",
        "code": "502908",
        "nameEn": "TOSSEH_TAAVON",
        "logoUrl": "bank-toseetaavon-logo-min.png"
    },
    {
        "name": "توسعه صادرات ایران",
        "code": "627648",
        "nameEn": "TOSSEH_SADERAT",
        "logoUrl": "bank-tosesaderatiran-logo-min.png"
    },
    {
        "name": "سینا",
        "code": "639346",
        "nameEn": "SINA",
        "logoUrl": "bank-sinah-logo-min.png"
    },
    {
        "name": "بانک رفاه کارگران",
        "code": "589463",
        "nameEn": "REFAH",
        "logoUrl": "bank-refahekargaran-logo-min.png"
    },
    {
        "name": "بانک شهر",
        "code": "504706",
        "nameEn": "SHAHR",
        "logoUrl": "bank-shahr-logo-min.png"
    },
    {
        "name": "اعتباری ملل",
        "code": "606256",
        "nameEn": "MELAL",
        "logoUrl": "bank-malivaetebarimellal-logo-min.png"
    },

    {
        "logoUrl": 'mehr-e-eghtesad.png',
        "nameEn": 'Mehr Eghtesad',
        "name": 'بانک مهر اقتصاد',
        "code": '639370',
    },

    {
        "logoUrl": 'noor-credit.png',
        "nameEn": 'Noor Credit',
        "name": 'موسسه اعتباری نور',
        "code": '507677',
    },

    {
        "logoUrl": 'tosee-credit.png',
        "nameEn": 'Noor Credit',
        "name": 'موسسه اعتباری توسعه',
        "code": '628157',
    },
    {
        "name": '(عسکریه)موسسه اعتباری ملل',
        "code": "606256",
        "nameEn": "MELAL",
        "logoUrl": "bank-malivaetebarimellal-askari-logo-min.png"
    },
    {
        "name": "مرکزی",
        "code": "10060",
        "nameEn": "TRADE_DEPARTMENT_CBI",
        "logoUrl": "bank-markazi-logo-min.png"
    }
]