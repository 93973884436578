import CardComponent from "../elements/card.component"
import CardTypeComponent from "../elements/card_type.component"
import PhoneInputComponent from "../elements/phone_input.component"
import NavigationBarComponent from "../elements/navigation_bar.component"
import PhoneCodeVerification from "./code_verification.component"
import VendorInfo from "./vendor.component"
import PaymentCompleteComponent from "./payment_complete.component"
import PaymentDetailsComponent from "./payment_details.component"
import { useEffect, useState } from 'react';
import axios from "axios";
import "../styles/payment.css"
import EmailInputComponent from './email.component';
import { connect } from "react-redux"
import { loginUserWithPhone } from '../../store/features/login/login.action';
import { VerifyOTPCode } from "../../store/features/otp/otp.action"
import { paymentInquiryAction } from "../../store/features/payment_first/payment.action"
import LoadingPage from "./loading"
import PaymentExpired from "../../views/expired/expired"
import { validate } from "../../helpers/cards/card.validation"
import { toast } from "react-toastify"





// @ts-ignore
const PaymentComponent: any = ({ query, ...props }) => {
  let [selectedItem, setSelectedItem] = useState(0)
  let [email, setEmail] = useState("mail@demo.com")
  let [phone, setPhone] = useState("")
  let [card, setCard] = useState(null);

  let [prCard, setPrCard] = useState(null);
  let [prPhone, setPrPhone] = useState(null);

  let [validation, setValidation] = useState({
    is_valid: false
  })


  useEffect(() => {
    parseRequired()
  }, [])


  const cardUpdated = (card: any) => {
    var _isValid = validate(card);
    if (_isValid === true) {
      setValidation({ is_valid: true })
      setCard(card)
    } else {
      setCard(null)
      setValidation({ is_valid: false })
    }
  }

  var hToU = (input: any) => {
    return decodeURIComponent('%' + input.match(/.{1,2}/g).join('%'));
  }


  var parseRequired = () => {
    var _url = window.location;
    var _pure = _url.search.replace("?", "");
    var _pt = _pure.split("&")[0];
    var _mt = _pure.split("&")[1];
    if (_pt !== undefined && _pt.includes("pt")) {
      let _ph = hToU(_pt.split("=")[1]);
      // @ts-ignore
      setPrPhone(_ph);
      setPhone(_ph)
    }
    if (_mt !== undefined && _mt.includes("metrica")) {
      let _cd = hToU(_mt.split("=")[1]);
      // @ts-ignore
      cardUpdated(_cd)
      // @ts-ignore
      setPrCard(_cd)
    }
  }

  var onReset = () => {
    props.loginUserWithPhone(phone, "shetab", email, prCard || card as any, () => { }, true);
  }


  var proceedLogin = (e: any) => {
    var _form = document.getElementById("step-one");
    // @ts-ignore
    if (_form.checkValidity()) {
      e.preventDefault();
      if (card == undefined || card == null) {
        return toast.error("شماره کارت وارد شده معتبر نمیباشد لطفا شماره کارت را بررسی نموده و مجددا تلاش کنید!")
      }
      props.loginUserWithPhone(phone, "shetab", email, card as any, nextStep);
    }
    // if ()
  }


  var nextStep = (step: number = 1) => {
    selectedItem += step;
    setSelectedItem(selectedItem)
  }


  if (query == null)
    return <PaymentExpired></PaymentExpired>
  return (
    <div className="home-page" >
      <div className="payment-overlay">
        <div className="indicators">
          <NavigationBarComponent selected={selectedItem} onSelect={(index: any) => { }} />
        </div>
        <div className="fields">
          {
            selectedItem == 0
              ? <div >
                <form id="step-one">
                  <VendorInfo query={query} />
                  <h3>پرداخت با کارت بانکی</h3>
                  {prCard !== null && prPhone !== null ?
                    <div className="prefilled-alert">
                      <p className="simple-text">
                        اطلاعات کارت شما از قبل دریافت شده است شما نیاز به وارد کردن شماره کارت یا شماره همراه ندارید لطفا نسبت به ادامه پرداخت اقدام کنید!
                      </p>
                    </div>
                  : 
                    <div></div>
                  }
                  <div className="sized-box-height-20"></div>
                  <div className="card-and-type">
                    <CardComponent card={prCard} onCardUpdate={cardUpdated} validation={validation} />
                  </div>
                  <div className="sized-box-height-40"></div>
                  <PhoneInputComponent phone={prPhone} disabled={props.login.is_loading} onLogin={proceedLogin} onPhoneSet={setPhone} />
                  <div className="sized-box-height-40"></div>
                </form>
              </div>
              : selectedItem == 1 ?
                <PhoneCodeVerification phone={phone} onReset={onReset} disabled={props.otp.is_loading} onSubmit={(code: any) => props.VerifyOTPCode(code, nextStep)} vendor={query} />
                : selectedItem == 2 ?
                  <PaymentDetailsComponent card={card} goNext={nextStep} vendor={query} />
                  : selectedItem == 3 ?
                    <PaymentCompleteComponent vendor={query} />
                    : <div></div>
          }
        </div>
      </div>
    </div>
  );
};


const mapPropsToState = (props: any) => {
  return {
    login: props.login,
    otp: props.otp
  }
}

export default connect(mapPropsToState, { loginUserWithPhone, VerifyOTPCode, paymentInquiryAction })(PaymentComponent);