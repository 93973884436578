
var initialState = {
    data: null,
    is_loading: false
}

export const PaymentInquiryTypes = {
    INQUIRY_PAYMENT: "INQUIRY_PAYMENT"
}

export const paymentInquiryReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case PaymentInquiryTypes.INQUIRY_PAYMENT:
            return {
                ...state,
                type: action.type,
                data: action.data,
                is_loading: action.is_loading
            }
        default:
            return {...state}
    }
}