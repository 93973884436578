
import { Component } from 'react';
import '../styles/404.css'
import BootLoader from "react-spinners/BeatLoader";
import { css } from '@emotion/react';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


export class CustomButton extends Component<any, any> {
    props = this.props as any;
    color = "#fff"
    loading = true;
    interval: any = null;


    constructor(props: any) {
        super(props);
        this.state = {
            counter: 5,
            interval: null
        }
    }


    render() {

        if (this.props.disabled === true) {
            return <button style={{ cursor: 'wait', opacity: '1' }} className="button-element" type="button" ><BootLoader color={this.color} loading={this.loading} css={override} size={10} /></button>
        }


        return (
            <button disabled={this.props.disabled} className="button-element" onClick={(e) => this.props.onClick(e)}>{this.props.text}</button>
        )
    }
}



export default CustomButton;
