
import VendorInfo from "./vendor.component";
import NumberFormat from 'react-number-format';
import "../styles/payment_complete.css"
import { ParseURI } from "../../utils/parse_uri";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import CustomButton from "./button.component";



// @ts-ignore
const PaymentCompleteComponent = ({ vendor }) => {

    var { merchent, transaction } = ParseURI();
    var [redirection, setRedirect] = useState(false);


    useEffect(() => {
        redirect()
    }, [redirection])


    var redirectPromise = () => {
        return new Promise((res, rej) => {
            setTimeout(() => {
                directCall();
                res(true);
            }, 5000)
        })
    }

    var directCall = () => {
        window.location.replace(vendor.cb_url);
    }

    var redirect = () => {
        toast.promise(redirectPromise, {
            success: "",
            pending: "درحال انتقال به سایت پذیرنده...",
            error: "خطایی بوجود آمده است. لطفا با دکمه به سایت پذیرنده برگردید."
        })
    }



    return (
        <div className="completion-wrapper">
            <div className="complete-icon"></div>
            <div className="sized-box-height-20"></div>
            <div className="complete-details">
                <h1 className="title-style">
                    پرداخت با موفقیت انجام شد!
                </h1>
                <p className="title-low-sized">
                    پرداخت شما با موفقیت انجام شد و مبلغ مورد نظر از کارت شما برداشته شد. درحال برگشت به سایت پذیرنده....
                </p>
            </div>
            <div className="sized-box-height-20"></div>
            <div className="reciept-item">
                <p className="title-low-sized">مبلغ پرداخت شده</p>
                {/* <p className="title-low-sized">12,0000,000 ریال</p> */}
                <NumberFormat className="title-low-sized" value={parseFloat(vendor.transaction.amount)} displayType={'text'} suffix="ریال" thousandSeparator={true} />
            </div>
            <div className="sized-box-height-10"></div>
            <div className="reciept-item">
                <p className="title-low-sized">شماره پیگیری</p>
                <p className="title-low-sized"><strong>{vendor.transaction.transaction}</strong></p>
            </div>
            <div className="sized-box-height-10"></div>
            <div className="reciept-item">
                <p className="title-low-sized">تاریخ تراکنش</p>
                <p className="title-low-sized">{vendor.transaction.created_at}</p>
            </div>
            <div className="sized-box-height-20"></div>
            <CustomButton text={"برگشت به سایت پذیرنده"} onClick={directCall}></CustomButton>
        </div>
    )
}
export default PaymentCompleteComponent;