


let initial = {
    data: undefined,
    is_loading: false
}

export const InitialTypes = {
    GET_TRANSACTION: "GET_TRANSACTION"
}



export const initalDataReducer = (state = initial, action: any) => {
    switch (action.type) {
        case InitialTypes.GET_TRANSACTION:
            return {
                ...state,
                type: action.type,
                data: action.data,
                is_loading: action.is_loading
            }
        default:
            return {
                ...state,
            }
    }
}


export {}