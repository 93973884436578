import { toast } from "react-toastify";
import { handleApiCallErrors } from "../../../utils/error_handler";
import { ParseURI } from "../../../utils/parse_uri";
import { getRequester } from "../../configs";
import { PinTypes } from "./pin.reducer";



export const sendDynamicPin = (unique_id: string) => (dispatch: any) => {
    var { merchent, transaction } = ParseURI();
    dispatch({
        type: PinTypes.SEND_PIN,
        data: null,
        is_loading: true
    })

    var _toastPromise = getRequester().post("/v1/payments/getDynamicPin", {
        "merchent_id": merchent,
        "transaction_uuid": transaction,
        "unique_id": unique_id
    }, {
        headers: {
            // @ts-ignore
            "IDS": sessionStorage.getItem("IDS")
        }
    }).then(body => {
        return dispatch({
            type: PinTypes.SEND_PIN,
            // @ts-expect-error
            data: body.data.data,
            is_loading: false
        })
    })


    toast.promise(_toastPromise, {
        pending: "درحال ارسال رمز پویا...",
        success: "رمز پویا ارسال شد...",
        error: "",
    }).catch(err => {
        handleApiCallErrors(err);
        dispatch({
            type: PinTypes.SEND_PIN,
            data: null,
            is_loading: false
        })
    })
}


