
import { useEffect, useRef, useState } from "react";
import VendorInfo from "./vendor.component"
import "../styles/code_verification.css"
import CustomButton from "./button.component";
import { countReset } from "console";
import { toast } from "react-toastify";



// @ts-ignore
const PhoneCodeVerification = ({ vendor, ...props }) => {

    let [started, setStarted] = useState(false);
    const isInitialMount = useRef(true);
    var [counter ,setCounter] = useState(60);
    var _interval: any = null;
    let [code, setCode] = useState("");

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            startCounter()
        } else {
            startCounter()
        }
    }, [])

    const startCounter = () => {
        if (_interval) {
            clearInterval(_interval)
        };
        setStarted(true);
        if (!started) {
            started = true;
            _interval = setInterval(() => {
                if (counter <= 1) {
                    setStarted(false);
                    clearInterval(_interval)
                };
                setCounter(counter--);
            }, 1000)
        }
    }


    var onSubmit = (e: any) => {
        var _phone = document.getElementById("phone-verification");
        // @ts-ignore
        if (_phone.checkValidity()) {
            e.preventDefault();
            props.onSubmit(code)
        }
    }



    return (
        <div className="phone-code-verification-element">
            <VendorInfo query={vendor} />
            <div className="sized-box-height-20"></div>
            <h2 className="title-style">تایید کد</h2>
            <div className="sized-box-height-40"></div>
            <div className="card-element verification-element">
                <div>
                    <h2 className="title-style">تایید شماره همراه</h2>
                    <p className="title-low-sized">
                        لطفا کد تاییدی که به شماره همراه شما ارسال گردیده است را وارد نمایید.
                    </p>
                </div>
                <form id="phone-verification" style={{ width: "100%" }}>
                    <div className="sized-box-height-40"></div>
                    <div className="field-wrapper">
                        <p className="title-low-sized">کد ارسال شده به شماره: <strong>{props.phone}</strong> را وارد کنید</p>
                        <div className="sized-box-height-10"></div>
                        <input pattern="[0-9]{4,8}" onChange={(e) => setCode(e.target.value.toString())} required className="input-style fullwidth-input" type="text" maxLength={8} placeholder="کد تایید"></input>
                    </div>
                    <div className="sized-box-height-40"></div>
                    <div className="field-wrapper">
                        {started ? <div className="code-retry">
                            <p style={{ opacity: ".5" }} className="title-low-sized">ارسال مجدد بعد از</p>
                            <p className="title-low-sized">{counter} ثانیه</p>
                        </div> : <div> <p onClick={() => {
                            props.onReset();
                            setCounter(60)
                            counter = 60;
                            toast.success("کد تایید مجددا ارسال شد.")
                            startCounter();
                        }} style={{ cursor: "pointer", opacity: ".5" }} className="title-low-sized">ارسال مجدد</p> </div>}
                        <div className="sized-box-height-20"></div>
                        <CustomButton text={"ادامه پرداخت"} disabled={props.disabled} onClick={onSubmit}></CustomButton>
                    </div>
                </form>

            </div>
        </div>
    )
}
export default PhoneCodeVerification;