import NumberFormat from 'react-number-format';
import "../styles/vendor.css"



// @ts-ignore
const VendorInfo = ({query}) => {
    
    return (
        <div className='vendor-info'>
            <div className="vendor-info-wrapper">
                <div>
                    <div className="vendor-avatar" style={{background: `url("${query.logo}")`}}></div>
                </div>
                <div className="sized-box-width-10"></div>
                <div className="vendor-name">
                    <h1 className="title-low-sized white-text">اطلاعات پذیرنده</h1>
                    <h1 className="title-style white-text">{query.name || ""}</h1>
                </div>
            </div>
            <div className="payment-info">
                <p className="title-low-sized white-text" style={{opacity: .7}}>قیمت نهایی</p>
                {/* <p className="title-style white-text">{query.transaction.amount} ریال</p> */}
                <NumberFormat className="title-style white-text" value={parseFloat(query.transaction.amount)} displayType={'text'} suffix="ریال" thousandSeparator={true}  />
            </div>
        </div>
    )
}
export default VendorInfo;