import { Route, BrowserRouter as Router, Switch } from "react-router-dom"
import Home from './views/payment/home';
import PaymentExpired from './views/expired/expired';
import BlockedPage from './views/blocked/blocked';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotFoundPage from "./components/elements/404.component";

const App = (props: any) => {

  return (
    <div className="main-application">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        theme={'colored'}
        newestOnTop={false}
        rtl={true}
        className="toast-wrapper"
      />
      <div className="overlay">
        <Router>
          <Route path="/payments/:merchent/:transaction" exact>
            <Home></Home>
          </Route>
          <Route path="/payments/expired" exact>
            <PaymentExpired></PaymentExpired>
          </Route>
          <Route path="/payments/blocked" exact>
            <BlockedPage></BlockedPage>
          </Route>
          <Route path="*">
            <NotFoundPage></NotFoundPage>
          </Route>
        </Router>
      </div>
    </div>
  );
}

export default App;
