import { banks } from "./cards.constant";




var banksHash: any = {};
var banksOutput: any = [];
for (var i = 0; i < banks.length; i++) {
    if (banks[i] == undefined) continue;
    // @ts-ignore
    banks[banks[i].code] = banks[i];

    banksOutput.push({
        nickname: banks[i].nameEn,
        persianName: banks[i].name,
        logo: banks[i].logoUrl,
        code: banks[i].code,
    });
}

const pattern = /[0-9]{16}/;
const pattern_code = /([0-9]{6})[0-9]{10}/;
const hash_code = /([0-9]{6})/;

export var outputs = banksOutput;

/*
function iso7064Mod97_10(card) {
  var remainder = card,
    block;
  while (remainder.length > 2) {
    block = remainder.slice(0, 9);
    remainder = (parseInt(block, 10) % 97) + remainder.slice(block.length);
  }
  return parseInt(remainder, 10) % 97;
}
*/

export const validate = function (str: any) {
    str = str.replace(/-/g, '');
    str = str.replace(/ /g, '');

    if (str.length !== 16) {
        return false;
    }

    if (!pattern.test(str)) {
        return false;
    }

    var nCheck = 0,
        bEven = false;
    str = str.replace(/\D/g, '');

    for (var n = str.length - 1; n >= 0; n--) {
        var cDigit = str.charAt(n),
            nDigit = parseInt(cDigit, 10);

        if (bEven) {
            if ((nDigit *= 2) > 9) nDigit -= 9;
        }

        nCheck += nDigit;
        bEven = !bEven;
    }

    return nCheck % 10 == 0;
};

export var isValid = validate;

export var recognize = function (str: any) {
    str = str.replace(/-/g, '');
    str = str.replace(/ /g, '');

    var _hash = hash_code.exec(str);
    // @ts-ignore
    if (_hash === undefined || _hash === null) {
        return false;
    }

    if (_hash.length == 0) return false;

    var _code = _hash[1];

    var bank = banks.find((_bank) => _bank !== undefined && _bank.code === _code.toString())
    if (bank == null || bank === undefined) {
        return false;
    }
    var result = {
        nickname: bank.nameEn,
        persianName: bank.name,
        logo: bank.logoUrl,
        code: bank.code,
    };
    return result;


};