
import { Component } from 'react';
import '../styles/404.css'
import BootLoader from "react-spinners/BeatLoader";
import { css } from '@emotion/react';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


export class TimerWidget extends Component<any, any> {
    props = this.props as any;
    color = "#fff"
    loading = true;
    interval: any = null;


    constructor(props: any) {
        super(props);
        this.state = {
            counter: 120,
            interval: null
        }
    }


    componentDidMount() {
        this.interval = setInterval(() => {
            if (this.state.counter <= 1) {
                console.log("Timer cleared...");
                this.props.onTimeout();
                return clearInterval(this.interval)
            }else {
                this.setState({counter: this.state.counter - 1})
            }
        }, 1000)
    }


    render() {
        return <button style={{ cursor: 'wait', opacity: '1' }} className="button-element" id="timer" type="button" dir="rtl" > {this.state.counter} ثانیه تا ارسال مجدد</button>
    }
}



export default TimerWidget;
